import React, { useState } from "react"
import styled from "styled-components"
import NumberFormat from "../utils/numberformat"
import CopyIcon from "./CopyIcon"
import GreetingMessageComponent from "./GreetingMessageComponent"

const GiftDetailsSection = styled.section`
  display: grid;
  grid-gap: 0.5rem;
  text-align: center;
  justify-items: center;
`
const GiftBrand = styled.h2`
  margin-block-start: 0.5rem;
  margin-block-end: 0rem;
`
const GiftPrice = styled.h2`
  color: var(--button-color);
  margin-block-start: 0rem;
  margin-block-end: 0.5rem;
`
const GiftMessageText = styled.p`
  text-align: center;
`
const GiftCardDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 1rem 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const GiftImageDiv = styled.div`
  width: 300px;
  height: 200px;
  justify-self: right;
  @media (max-width: 768px) {
    justify-self: center;
  }
`
const GiftImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`
const GiftCardNumberPinDiv = styled.div`
  justify-self: left;
  text-align: left;
  display: grid;
  @media (max-width: 768px) {
    justify-self: center;
    text-align: center;
    grid-gap: 0.5rem;
  }
`
const GiftCardNumberPinDivTitle = styled.h6`
  font-family: "OpenSans-Bold";
  color: var(--button-color);
  margin-block-start: 0rem;
  margin-block-end: 0rem;
`
const GiftDetailsSmallText = styled.p`
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-top: 0;
`
const GiftDetailsBoldText = styled.p`
  font-family: "OpenSans-Bold";
  margin-top: 0;
  margin-bottom: 0;
`

export default function GiftDetails(props) {
  return (
    <GiftDetailsSection>
      <GiftBrand>{props.giftDetails.brand_name}</GiftBrand>
      <GiftPrice>
        <NumberFormat
          value={props.giftDetails.gift_card_amount}
          currency={true}
        />
      </GiftPrice>
      <GiftMessageText>
        {(props.giftDetails.experience &&
          props.giftDetails.experience.greeting_card &&
          props.giftDetails.experience.greeting_card.message) ||
          ""}
      </GiftMessageText>
      <GiftCardDetails>
        <GiftImageDiv>
          <GiftImage
            src={props.giftcard.product_image}
            alt={`${props.giftDetails.brand_name}`}
          />
        </GiftImageDiv>
        <GiftCardNumberPinDiv>
          <GiftCardNumberPinDivTitle>
            Gift Card Details
          </GiftCardNumberPinDivTitle>
          {props.giftDetails.instore_card_number &&
            props.giftDetails.instore_card_number !== "" && (
              <div className="instore_card_number">
                <GiftDetailsSmallText>
                  In Store Card Number
                </GiftDetailsSmallText>
                <GiftDetailsBoldText>
                  {props.giftDetails.instore_card_number}
                </GiftDetailsBoldText>
              </div>
            )}
          {props.giftDetails.online_card_number &&
            props.giftDetails.online_card_number !== "" && (
              <div className="online_card_number">
                <GiftDetailsSmallText>Card Number</GiftDetailsSmallText>
                <GiftDetailsBoldText>
                  {props.giftDetails.online_card_number}
                </GiftDetailsBoldText>
              </div>
            )}
          <div className="pin_number">
            <GiftDetailsSmallText>PIN</GiftDetailsSmallText>
            <GiftDetailsBoldText>{props.giftDetails.pin}</GiftDetailsBoldText>
          </div>
        </GiftCardNumberPinDiv>
      </GiftCardDetails>
    </GiftDetailsSection>
  )
}

const GiftDetailsV2Wrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-bottom: 3rem;
  width: 100%;
`

const GiftBrandV2 = styled.h2`
  font-family: "FuturaPT-Bold";
  text-align: center;
  margin: 0;
  font-size: 2.5rem;
`
const GiftValueV2 = styled.h2`
  font-family: "FuturaPT-Bold";
  text-align: center;
  margin: 0;
  font-size: 2.5rem;
  color: #14a0b3;
  margin-bottom: 1.5rem;
`
const GiftMessageTextV2 = styled.p`
  text-align: center;
  font-family: "OpenSans-Bold";
  color: #a09c9c;
  margin: 1rem 0;
`
const GiftImageDivV2 = styled.div`
  width: 100%;
  justify-self: right;
  display: grid;
  justify-items: center;
  align-items: center;
  @media (max-width: 768px) {
    justify-self: center;
  }
`
const GiftImageV2 = styled.img`
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 0 10px 0 #2d2d2d35;
  border-radius: 15px;
`
const GiftImageAndCredentials = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  padding: 0 3rem;
  @media (max-width: 1000px) {
    justify-content: center;
    padding: 0;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }
  @media (max-width: 550px) {
    padding: 0 0.5rem;
  }
`
const GiftCredentials = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
`
const GiftDetailsSmallTextV2 = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-top: 0;
`
const GiftDetailsBoldTextV2 = styled.p`
  font-family: "OpenSans-Bold";
  margin-top: 0;
  margin-bottom: 0;
`
const InstoreCardNumber = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #2d2d2d35;
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 5rem;
  align-self: bottom;
  display: grid;
  align-items: center;
  @media (max-width: 1000px) {
    min-height: 4rem;
  }
`
const OnlineCardNumber = styled.div`
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #2d2d2d35;
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 5rem;
  align-self: bottom;
  display: grid;
  align-items: center;
  @media (max-width: 1000px) {
    min-height: 4rem;
  }
`
const CopyTextButton = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  right: 1rem;
  outline: none;
  border: none;
  background: transparent;
  &::before {
    content: attr(data-copytooltip);
    position: absolute;
    top: -2.5rem;
    right: 0;
    width: 75px;
    height: 35px;
    background-color: #2d2d2d;
    color: white;
    display: ${props => (props.copyStatus ? "grid" : "none")};
    align-items: center;
    text-align: center;
    border-radius: 5px;
    font-size: 0.875rem;
  }
`
const PinNumber = styled.div`
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #2d2d2d35;
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 5rem;
  align-self: top;
  display: grid;
  align-items: center;
  @media (max-width: 1000px) {
    min-height: 4rem;
  }
`

const ExpiryDateBlock = styled.div`
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #2d2d2d35;
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 5rem;
  align-self: top;
  display: grid;
  align-items: center;
  @media (max-width: 1000px) {
    min-height: 4rem;
  }
`

export function GiftDetailsVersion2(props) {
  const [copyCardNumberStatus, setCopyCardNumberStatus] = useState(false)
  const [copyPinStatus, setCopyPinStatus] = useState(false)
  const [copyExpiryDateStatus, setCopyExpiryDateStatus] = useState(false)

  function handleCopyClick(e, callBackHook, copiedValue) {
    e.preventDefault()
    navigator.clipboard.writeText(copiedValue)
    callBackHook(true)
    setTimeout(() => {
      callBackHook(false)
    }, 1000)
  }

  return (
    <GiftDetailsV2Wrapper className="gift-details-v2__wrapper">
      <GiftBrandV2 className="gift-card-brand-v2">
        {props.giftDetails.brand_name}
      </GiftBrandV2>
      <GiftValueV2 className="gift-card-value-v2">
        <NumberFormat
          value={props.giftDetails.gift_card_amount}
          currency={true}
        />
      </GiftValueV2>
      {props.greetingMessage && (
        <GreetingMessageComponent greetingMessage={props.greetingMessage} />
      )}
      <GiftImageAndCredentials className="gift-image-and-credentials-v2">
        <GiftImageDivV2>
          <GiftImageV2
            src={props.giftcard.product_image}
            alt={`${props.giftDetails.brand_name}`}
          />
        </GiftImageDivV2>
        <GiftCredentials className="gift-credentials-v2__wrapper">
          {props.giftDetails.instore_card_number && (
            <InstoreCardNumber className="instore-card-number-v2">
              <GiftDetailsSmallTextV2>
                In Store Card Number
              </GiftDetailsSmallTextV2>
              <GiftDetailsBoldTextV2>
                {props.giftDetails.instore_card_number}
              </GiftDetailsBoldTextV2>
            </InstoreCardNumber>
          )}
          <OnlineCardNumber className="online-card-number-v2">
            <GiftDetailsSmallTextV2>
              {props.giftDetails.instore_card_number && "Online"} Card Number
            </GiftDetailsSmallTextV2>
            <GiftDetailsBoldTextV2>
              {props.giftDetails.online_card_number}
            </GiftDetailsBoldTextV2>
            <CopyTextButton
              id="copy-card-number"
              data-copytooltip="Copied"
              copyStatus={copyCardNumberStatus}
              onClick={e =>
                handleCopyClick(
                  e,
                  setCopyCardNumberStatus,
                  props.giftDetails.online_card_number
                )
              }
            >
              <CopyIcon />
            </CopyTextButton>
          </OnlineCardNumber>
          {props.giftDetails.pin && (
            <PinNumber className="pin-number-v2">
              <GiftDetailsSmallTextV2>
                {props.giftDetails.brand_group === "BHNULTIMATE"
                  ? `Security Code`
                  : `PIN`}
              </GiftDetailsSmallTextV2>
              <GiftDetailsBoldTextV2>
                {props.giftDetails.pin}
              </GiftDetailsBoldTextV2>
              <CopyTextButton
                data-copytooltip="Copied"
                copyStatus={copyPinStatus}
                onClick={e =>
                  handleCopyClick(e, setCopyPinStatus, props.giftDetails.pin)
                }
              >
                <CopyIcon />
              </CopyTextButton>
            </PinNumber>
          )}
          {props.giftDetails.brand_group === "BHNULTIMATE" &&
            props.giftDetails.expiry_date && (
              <ExpiryDateBlock className="expiry-date-v2">
                <GiftDetailsSmallTextV2>Expiry Date</GiftDetailsSmallTextV2>
                <GiftDetailsBoldTextV2>
                  {props.giftDetails.expiry_date}
                </GiftDetailsBoldTextV2>
                <CopyTextButton
                  data-copytooltip="Copied"
                  copyStatus={copyExpiryDateStatus}
                  onClick={e =>
                    handleCopyClick(
                      e,
                      setCopyExpiryDateStatus,
                      props.giftDetails.expiry_date
                    )
                  }
                >
                  <CopyIcon />
                </CopyTextButton>
              </ExpiryDateBlock>
            )}
        </GiftCredentials>
      </GiftImageAndCredentials>
    </GiftDetailsV2Wrapper>
  )
}

const StarGiftBrandV2 = styled.h2`
  font-family: "CamptonLight";
  font-size: 2rem;
  text-align: center;
  margin: 0;
`
const StarGiftValueV2 = styled.h2`
  font-family: "CamptonLight";
  font-size: 2rem;
  text-align: center;
  margin: 0;
`
const StarGiftMessageTextV2 = styled.p`
  text-align: center;
  font-family: "CamptonLight";
  font-size: 1rem;
  color: #2d2d2d;
  margin: 1rem 0;
`
const StarGiftDetailsBoldTextV2 = styled.p`
  font-family: "CamptonMedium";
  margin-top: 0;
  margin-bottom: 0;
`
const StarGiftDetailsSmallTextV2 = styled.p`
  font-family: "CamptonLight";
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-top: 0;
`

export function StarGiftDetailsVersion2(props) {
  const [copyStatus, setCopyStatus] = useState(false)

  function handleCopyClick(e) {
    e.preventDefault()
    navigator.clipboard.writeText(props.giftDetails.online_card_number)
    setCopyStatus(true)
    setTimeout(() => {
      setCopyStatus(false)
    }, 1000)
  }

  return (
    <GiftDetailsV2Wrapper className="gift-details-v2__wrapper">
      <StarGiftBrandV2 className="gift-card-brand-v2">
        {props.giftDetails.brand_name}
      </StarGiftBrandV2>
      <StarGiftValueV2 className="gift-card-value-v2">
        <NumberFormat
          value={props.giftDetails.gift_card_amount}
          currency={true}
        />
      </StarGiftValueV2>
      <StarGiftMessageTextV2 className="gift-message-v2">
        {props.giftDetails.greeting_message || ""}
      </StarGiftMessageTextV2>
      <GiftImageAndCredentials className="gift-image-and-credentials-v2">
        <GiftImageDivV2>
          <GiftImageV2
            src={props.giftcard.product_image}
            alt={`${props.giftDetails.brand_name}`}
          />
        </GiftImageDivV2>
        <GiftCredentials className="gift-credentials-v2__wrapper">
          <OnlineCardNumber className="online-card-number-v2">
            <StarGiftDetailsSmallTextV2>
              {props.giftDetails.instore_card_number && "Online"} Card Number
            </StarGiftDetailsSmallTextV2>
            <StarGiftDetailsBoldTextV2>
              {props.giftDetails.online_card_number}
            </StarGiftDetailsBoldTextV2>
            <CopyTextButton
              data-copytooltip="Copied"
              copyStatus={copyStatus}
              onClick={e => handleCopyClick(e)}
            >
              <CopyIcon />
            </CopyTextButton>
          </OnlineCardNumber>
          {props.giftDetails.pin && (
            <PinNumber className="pin-number-v2">
              <StarGiftDetailsSmallTextV2>PIN</StarGiftDetailsSmallTextV2>
              <StarGiftDetailsBoldTextV2>
                {props.giftDetails.pin}
              </StarGiftDetailsBoldTextV2>
            </PinNumber>
          )}
        </GiftCredentials>
      </GiftImageAndCredentials>
    </GiftDetailsV2Wrapper>
  )
}

const ExpiryV2Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export function ExpiryVersion2(props) {
  return (
    <ExpiryV2Wrapper className="expiry-v2__wrapper">
      <p>Expiry</p>&nbsp;&nbsp;
      <GiftDetailsBoldTextV2>
        {props.giftDetails.expiry || "-"}
      </GiftDetailsBoldTextV2>
    </ExpiryV2Wrapper>
  )
}

const StarExpiryV2Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .label {
    font-family: "CamptonLight";
  }
`

export function StarExpiryVersion2(props) {
  return (
    <StarExpiryV2Wrapper className="expiry-v2__wrapper">
      <p className="label">Expiry</p>&nbsp;&nbsp;
      <StarGiftDetailsBoldTextV2>
        {props.giftDetails.expiry || "-"}
      </StarGiftDetailsBoldTextV2>
    </StarExpiryV2Wrapper>
  )
}

const TermsV2Wrapper = styled.div`
  margin: 0.5rem 0;
  a {
    text-decoration: underline;
    font-size: 0.95rem;
    color: #2d2d2d;
  }
`

export function TermsAndConditionsVersion2(props) {
  return (
    <TermsV2Wrapper className="terms-v2__wrapper">
      <a
        href={props.giftDetails.terms_url || "#"}
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        Gift Card Terms & Conditions
      </a>
    </TermsV2Wrapper>
  )
}

const StarTermsV2Wrapper = styled.div`
  margin: 0.5rem 0;
  a {
    font-family: "CamptonLight";
    text-decoration: underline;
    font-size: 0.95rem;
    color: #14a0b3;
  }
`

export function StarTermsAndConditionsVersion2(props) {
  return (
    <StarTermsV2Wrapper className="terms-v2__wrapper">
      <a
        href={props.giftDetails.terms_url || "#"}
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        Gift Card Terms & Conditions
      </a>
    </StarTermsV2Wrapper>
  )
}
