import React from "react"
import styled from "styled-components"
import InfoIcon from "../assets/images/Info.png"

const ViewGiftSection = styled.section`
  display: grid;
  justify-items: center;
  align-items: center;
`

const PrimaryButtonLink = styled.a`
  background: var(--button-color);
  border: 2px solid var(--button-color);
  border-radius: 50px;
  outline: none;
  padding: 0.75rem 2rem;
  margin: 5px;
  min-width: "300px";
  @media (max-width: 800px) {
    min-width: "300px";
  }
  @media (max-width: 500px) {
    min-width: "200px";
    margin: 5px 0;
  }
  font-size: var(--button-font-size);
  font-family: var(--bold-paragraph-font);
  text-transform: uppercase;
  color: white;
  &:hover {
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
`

export default function ViewGift(props) {
  return (
    <ViewGiftSection>
      <PrimaryButtonLink
        href={props.giftDetails ? props.giftDetails.gift_link : "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Digital Gift Card
      </PrimaryButtonLink>
    </ViewGiftSection>
  )
}

const ButtonBlockVersion2Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

const SecondaryButtonLink = styled.a`
  background: var(--button-color);
  border: 2px solid var(--button-color);
  border-radius: 10px;
  outline: none;
  padding: 0.75rem 2rem;
  margin: 5px;
  max-width: 350px;
  height: 63px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  @media (max-width: 800px) {
    min-width: "300px";
  }
  @media (max-width: 500px) {
    min-width: "200px";
    margin: 5px 0;
  }
  font-size: var(--button-font-size);
  font-family: var(--bold-paragraph-font);
  text-transform: uppercase;
  color: white;
  &:hover {
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
`
const SecondaryOutlineButtonLink = styled.a`
  position: relative;
  background: white;
  border: 2px solid #2d2d2d;
  border-radius: 10px;
  outline: none;
  padding: 0.75rem 2rem;
  margin: 5px;
  width: 165px;
  height: 63px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  @media (max-width: 800px) {
    min-width: "300px";
  }
  @media (max-width: 500px) {
    min-width: "200px";
    margin: 5px 0;
  }
  font-size: var(--button-font-size);
  font-family: var(--bold-paragraph-font);
  text-transform: uppercase;
  color: #2d2d2d;
  &:hover {
    background: var(--button-color);
    border: 2px solid var(--button-color);
    color: white;
  }
`
const InfoContent = styled.div`
  display: none;
`

const InfoImageDiv = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .info-content {
      display: block;
      position: absolute;
      top: -4.3rem;
      right: calc(100% + 55px * -1);
      background-color: #ececec;
      border-radius: 5px;
      color: #2d2d2d;
      width: 220px;
      padding: 0.75rem;
      display: grid;
      text-align: center;
      align-items: center;
      font-size: 0.75rem;
      box-shadow: 0 0 5px 0 #2d2d2d20;
    }
    &::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      right: 0;
      width: 0;
      height: 0;
      border-top: 0.5rem solid #ececec;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
    }
  }
`

const InfoContentParagraph = styled.p`
  margin: 0;
  font-size: 0.75rem;
`

const InfoImage = styled.img`
  width: 1rem;
  height: 1rem;
`

const SaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

export function ButtonBlockVersion2(props) {
  return (
    <ButtonBlockVersion2Wrapper className="button-block-v2__wrapper">
      <SecondaryButtonLink
        href={props.giftDetails ? props.giftDetails.gift_link : "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.giftDetails.brand_name.includes("eGift")
          ? `pick your digital gift card`
          : props.giftDetails.card_type == "TCN"
          ? `Activate Card`
          : `View Gift Card`}
      </SecondaryButtonLink>
      {false && (
        <SaveButtonWrapper>
          <SecondaryOutlineButtonLink
            href={props.giftDetails ? props.giftDetails.gift_link : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Save Card
          </SecondaryOutlineButtonLink>
          <InfoImageDiv>
            <InfoImage src={InfoIcon} alt="info" />
            <InfoContent className="info-content">
              <InfoContentParagraph>
                Add this gift card to your <strong>eGift it</strong> account and
                access it at any time
              </InfoContentParagraph>
            </InfoContent>
          </InfoImageDiv>
        </SaveButtonWrapper>
      )}
    </ButtonBlockVersion2Wrapper>
  )
}

const StarButtonBlockVersion2Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

const StarSecondaryButtonLink = styled.a`
  width: 334px;
  height: 47px;
  padding-top: 0.25rem;
  background-color: white;
  border: 1px solid #c9ab5d;
  font-family: "CamptonMedium";
  font-size: 0.875rem;
  color: #2d2d2d;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  box-shadow: 0 2px 5px 0 #2d2d2d10;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    outline: 2px solid #c9ab5d;
  }
`
const StarSecondaryOutlineButtonLink = styled.a`
  position: relative;
  background: white;
  border: 2px solid #2d2d2d;
  border-radius: 10px;
  outline: none;
  padding: 0.75rem 2rem;
  margin: 5px;
  width: 165px;
  height: 63px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  @media (max-width: 800px) {
    min-width: "300px";
  }
  @media (max-width: 500px) {
    min-width: "200px";
    margin: 5px 0;
  }
  font-size: var(--button-font-size);
  font-family: var(--bold-paragraph-font);
  text-transform: uppercase;
  color: #2d2d2d;
  &:hover {
    background: var(--button-color);
    border: 2px solid var(--button-color);
    color: white;
  }
`
const StarInfoContent = styled.div`
  display: none;
`

const StarInfoImageDiv = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .info-content {
      display: block;
      position: absolute;
      top: -4.3rem;
      right: calc(100% + 55px * -1);
      background-color: #ececec;
      border-radius: 5px;
      color: #2d2d2d;
      width: 220px;
      padding: 0.75rem;
      display: grid;
      text-align: center;
      align-items: center;
      font-size: 0.75rem;
      box-shadow: 0 0 5px 0 #2d2d2d20;
    }
    &::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      right: 0;
      width: 0;
      height: 0;
      border-top: 0.5rem solid #ececec;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
    }
  }
`

const StarInfoContentParagraph = styled.p`
  margin: 0;
  font-size: 0.75rem;
`

const StarInfoImage = styled.img`
  width: 1rem;
  height: 1rem;
`

const StarSaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

export function StarButtonBlockVersion2(props) {
  return (
    <StarButtonBlockVersion2Wrapper className="button-block-v2__wrapper">
      <StarSecondaryButtonLink
        href={props.giftDetails ? props.giftDetails.gift_link : "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Redeem Gift Card
      </StarSecondaryButtonLink>
      {false && (
        <StarSaveButtonWrapper>
          <StarSecondaryOutlineButtonLink
            href={props.giftDetails ? props.giftDetails.gift_link : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Save Card
          </StarSecondaryOutlineButtonLink>
          <StarInfoImageDiv>
            <StarInfoImage src={InfoIcon} alt="info" />
            <StarInfoContent className="info-content">
              <StarInfoContentParagraph>
                Add this gift card to your <strong>eGift it</strong> account and
                access it at any time
              </StarInfoContentParagraph>
            </StarInfoContent>
          </StarInfoImageDiv>
        </StarSaveButtonWrapper>
      )}
    </StarButtonBlockVersion2Wrapper>
  )
}
