import React from "react"

export default function CopyIcon() {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ filter: "none" }}
    >
      <title>Copy</title>
      <g
        id="18-Received-page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD---Instore-&amp;-Online"
          transform="translate(-1008.000000, -821.000000)"
          stroke="#58585B"
          strokeWidth="1.5"
        >
          <g id="Bottom-section" transform="translate(364.000000, 579.000000)">
            <g id="Card-Details" transform="translate(66.000000, 147.000000)">
              <g id="Group-2" transform="translate(322.000000, 0.000000)">
                <g id="Group-3" transform="translate(0.000000, 73.000000)">
                  <g id="Group-5" transform="translate(257.000000, 23.000000)">
                    <path
                      d="M16.146,16.4844 L4.128,16.4844 C3.862,16.4844 3.643,16.2664 3.643,15.9994 L3.643,3.9824 C3.643,3.7144 3.862,3.4974 4.128,3.4974 L16.146,3.4974 C16.413,3.4974 16.631,3.7144 16.631,3.9824 L16.631,15.9994 C16.631,16.2664 16.413,16.4844 16.146,16.4844 Z"
                      id="Stroke-1"
                    ></path>
                    <path
                      d="M12.1553,0.3751 L1.2383,0.3751 C0.7613,0.3751 0.3753,0.7621 0.3753,1.2381 L0.3753,12.1461"
                      id="Stroke-3"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
